<template>
  <Tooltip
    :text="text"
    :placement="placement"
    :show-triggers="showTriggers"
    :hide-triggers="hideTriggers"
    :delay="delay"
  >
    <slot></slot>
  </Tooltip>
</template>

<script setup lang="ts">
import { hydrateOnVisible } from 'vue'

defineProps({
  text: {
    type: String as PropType<string>,
    required: true,
  },
  placement: {
    type: String as PropType<string>,
    default: 'auto',
  },
  showTriggers: {
    type: Array as PropType<string[]>,
    default: () => ['click', 'hover'],
  },
  hideTriggers: {
    type: Array as PropType<string[]>,
    default: () => ['click', 'hover'],
  },
  delay: {
    type: [Number, Object] as PropType<
      | number
      | {
          show: number
          hide: number
        }
    >,
    default: undefined,
  },
})

const Tooltip = defineAsyncComponent({
  loader: () => import('~/components/ui/popovers/Tooltip.vue'),
  hydrate: hydrateOnVisible(),
})
</script>
